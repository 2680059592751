import './assets/js/cookieconsent.js';
import './assets/js/jquery.js';
import './assets/js/bootstrap.min.js';
// import './assets/js/waypoints.min.js';
import './assets/js/main.js';


// function addToDom(text) {
//     let h3 = document.createElement('h3');
//     h3.innerText = text;
//     document.body.append(h3);
// }

// addToDom('david');