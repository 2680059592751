
var jquery = require("jquery");
window.$ = window.jQuery = jquery; // notice the definition of global variables here

(function ($) {
    "use strict";
    jQuery(document).ready(function ($) {
        /*------------------------------
            counter section activation
        -------------------------------*/
        // var counternumber = $('.count-num');
        // counternumber.counterUp({
        //     delay: 20,
        //     time: 3000
        // });
        /*--------------------
            wow js init
        --------------------*/
        // new WOW().init();
        /*----------------------------
            portfolio menu active
        ----------------------------*/
        var portfolioMenu = '.portfolio-menu li';
        $(document).on('click', portfolioMenu, function () {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');
        });

        /*----------------------------------
            magnific popup activation
        ----------------------------------*/
        // $('.image-popup').magnificPopup({
        //     type: 'image'
        // });
        // $('.video-play-btn,.play-video-btn').magnificPopup({
        //     type: 'video'
        // });
        /*-------------------------------
            back to top
        ------------------------------*/
        $(document).on('click', '.back-to-top', function () {
            $("html,body").animate({
                scrollTop: 0
            }, 2000);
        });
        /*------------------------------
            smoth achor effect
        ------------------------------*/
        $(document).on('click', '#appside_main_menu li a', function (e) {
            var anchor = $(this).attr('href');
            var link = anchor.slice(0, 1);
            if ('#' == link) {
                e.preventDefault();
                var top = $(anchor).offset().top;
                $('html, body').animate({
                    scrollTop: $(anchor).offset().top
                }, 1000);
                $(this).parent().addClass('active').siblings().removeClass('active');
            }

        });


        /*----------------------------------------
             screenshort carousel
         ----------------------------------------*/
        var $brandCarousel = $('.brand-carousel');
        if ($brandCarousel.length > 0) {
            $brandCarousel.owlCarousel({
                loop: true,
                autoplay: true, //true if you want enable autoplay
                autoPlayTimeout: 1000,
                margin: 30,
                dots: false,
                nav: true,
                smartSpeed: 3000,
                navText: ['', ''],
                responsive: {
                    0: {
                        items: 1,
                        nav: false
                    },
                    360: {
                        items: 2,
                        nav: false
                    },
                    767: {
                        items: 2,
                        nav: false
                    },
                    768: {
                        items: 2,
                        nav: false
                    },
                    960: {
                        items: 3,
                        nav: false
                    },
                    1200: {
                        items: 4
                    },
                    1920: {
                        items: 5
                    }
                }
            });
        }
        /*----------------------------------------
            screenshort carousel
        ----------------------------------------*/
        var $screenshortCarouselTwo = $('.screenshort-carousel-02');
        if ($screenshortCarouselTwo.length > 0) {
            $screenshortCarouselTwo.owlCarousel({
                loop: true,
                autoplay: true, //true if you want enable autoplay
                autoPlayTimeout: 1000,
                margin: 30,
                dots: false,
                nav: true,
                smartSpeed: 3000,
                navText: ['', ''],
                responsive: {
                    0: {
                        items: 1,
                        nav: false
                    },
                    414: {
                        items: 2,
                        nav: false
                    },
                    767: {
                        items: 2,
                        nav: false
                    },
                    768: {
                        items: 2,
                        nav: false
                    },
                    960: {
                        items: 3,
                        nav: false
                    },
                    1200: {
                        items: 4
                    },
                    1920: {
                        items: 3
                    }
                }
            });
        }
        /*----------------------------------------
            screenshort carousel
        ----------------------------------------*/
        var $screenshortCarousel = $('.screenshort-carousel');
        if ($screenshortCarousel.length > 0) {
            $screenshortCarousel.owlCarousel({
                loop: true,
                autoplay: true, //true if you want enable autoplay
                autoPlayTimeout: 1000,
                margin: 30,
                dots: false,
                nav: true,
                smartSpeed: 3000,
                navText: ['', ''],
                responsive: {
                    0: {
                        items: 1,
                        nav: false
                    },
                    767: {
                        items: 2,
                        nav: false
                    },
                    768: {
                        items: 2,
                        nav: false
                    },
                    960: {
                        items: 3,
                        nav: false
                    },
                    1200: {
                        items: 4
                    },
                    1920: {
                        items: 4
                    }
                }
            });
        }
        /*----------------------------------------
            testimonial carousel
        ----------------------------------------*/
        var $testimonialCarousel = $('.testimonial-carousel');
        if ($testimonialCarousel.length > 0) {
            $testimonialCarousel.owlCarousel({
                loop: true,
                autoplay: true, //true if you want enable autoplay
                autoPlayTimeout: 1000,
                margin: 30,
                dots: true,
                nav: true,
                smartSpeed: 3000,
                animateIn: 'fadeIn',
                animateOut: "fadeOut",
                navText: ['', ''],
                responsive: {
                    0: {
                        items: 1,
                        nav: false
                    },
                    767: {
                        items: 1,
                        nav: false
                    },
                    768: {
                        items: 1,
                        nav: false
                    },
                    960: {
                        items: 1,
                        nav: false
                    },
                    1200: {
                        items: 1
                    },
                    1920: {
                        items: 1
                    }
                }
            });
        }
        /*----------------------------------------
            testimonialtwo carousel
        ----------------------------------------*/
        var $testimonialCarouselTwo = $('.testimonial-carousel-02');
        if ($testimonialCarouselTwo.length > 0) {
            $testimonialCarouselTwo.owlCarousel({
                loop: true,
                autoplay: false, //true if you want enable autoplay
                autoPlayTimeout: 1000,
                margin: 60,
                dots: true,
                nav: true,
                smartSpeed: 3000,
                animateIn: 'fadeIn',
                animateOut: "fadeOut",
                navText: ['', ''],
                center: true,
                stagePadding: 100,
                responsive: {
                    0: {
                        items: 1,
                        nav: false,
                        center: false,
                        stagePadding: 10
                    },
                    414: {
                        items: 1,
                        nav: false,
                        center: false,
                        stagePadding: 10
                    },
                    767: {
                        items: 1,
                        nav: false,
                        center: false,
                        stagePadding: 10
                    },
                    768: {
                        items: 1,
                        nav: false
                    },
                    960: {
                        items: 1,
                        nav: false,
                        center: false
                    },
                    1200: {
                        items: 2,
                        nav: false,
                        center: false,
                        stagePadding: 10
                    },
                    1920: {
                        items: 2
                    }
                }
            });
        }
        /*----------------------------------------
            testimonialtwo carousel
        ----------------------------------------*/
        var $testimonialCarouselThree = $('.testimonial-carousel-03');
        if ($testimonialCarouselThree.length > 0) {
            $testimonialCarouselThree.owlCarousel({
                loop: true,
                autoplay: false, //true if you want enable autoplay
                autoPlayTimeout: 1000,
                margin: 30,
                dots: true,
                nav: true,
                smartSpeed: 3000,
                animateIn: 'fadeIn',
                animateOut: "fadeOut",
                navText: ['', ''],
                responsive: {
                    0: {
                        items: 1,
                        nav: false
                    },
                    767: {
                        items: 1,
                        nav: false
                    },
                    768: {
                        items: 1,
                        nav: false
                    },
                    960: {
                        items: 2,
                        nav: false,
                    },
                    1200: {
                        items: 3
                    },
                    1920: {
                        items: 3
                    }
                }
            });
        }
        /*----------------------------------------
            Team carousel
        ----------------------------------------*/
        var $teamCarousel = $('.team-carousel');
        if ($teamCarousel.length > 0) {
            $teamCarousel.owlCarousel({
                loop: true,
                autoplay: true, //true if you want enable autoplay
                autoPlayTimeout: 1000,
                margin: 30,
                dots: true,
                nav: true,
                smartSpeed: 3000,
                animateIn: 'fadeIn',
                animateOut: "fadeOut",
                navText: ['', ''],
                responsive: {
                    0: {
                        items: 1,
                        nav: false
                    },
                    414: {
                        items: 1,
                        nav: false
                    },
                    520: {
                        items: 2,
                        nav: false
                    },
                    767: {
                        items: 2,
                        nav: false
                    },
                    768: {
                        items: 2,
                        nav: false
                    },
                    960: {
                        items: 3,
                        nav: false
                    },
                    1200: {
                        items: 4
                    },
                    1920: {
                        items: 4
                    }
                }
            });
        }


    });


    //define variable for store last scrolltop
    var lastScrollTop = '';
    $(window).on('scroll', function () {
        /*---------------------------
            back to top show / hide
        ---------------------------*/
        var ScrollTop = $('.back-to-top');
        if ($(window).scrollTop() > 1000) {
            ScrollTop.fadeIn(1000);
        } else {
            ScrollTop.fadeOut(1000);
        }
        /*--------------------------
         sticky menu activation
        ---------------------------*/
        var st = $(this).scrollTop();
        var mainMenuTop = $('.navbar-area');
        if ($(window).scrollTop() > 1000) {
            if (st > lastScrollTop) {
                // hide sticky menu on scrolldown 
                mainMenuTop.removeClass('nav-fixed');

            } else {
                // active sticky menu on scrollup 
                mainMenuTop.addClass('nav-fixed');
            }

        } else {
            mainMenuTop.removeClass('nav-fixed ');
        }
        lastScrollTop = st;

    });

    $(window).on('load', function () {
        /*-----------------------------
            preloader
        -----------------------------*/
        var preLoder = $("#preloader");
        preLoder.fadeOut(1000);
        /*-----------------------------
            back to top
        -----------------------------*/
        var backtoTop = $('.back-to-top')
        backtoTop.fadeOut(100);

        console.log('balbalba');
        /*---------------------------------*/
        // obtain plugin
        var cc = initCookieConsent();

        // run plugin with your configuration
        cc.run({
            // force_consent: true,
            current_lang: 'en',
            autoclear_cookies: true,                   // default: false
            page_scripts: true,                        // default: false

            // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
            // delay: 0,                               // default: 0
            // auto_language: '',                      // default: null; could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            // force_consent: false,                   // default: false
            // hide_from_bots: true,                   // default: true
            // remove_cookie_tables: false             // default: false
            // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
            // cookie_expiration: 182,                 // default: 182 (days)
            // cookie_necessary_only_expiration: 182   // default: disabled
            // cookie_domain: location.hostname,       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'Lax',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            // revision: 0,                            // default: 0

            onFirstAction: function (user_preferences, cookie) {
                // callback triggered only once on the first accept/reject action
            },

            onAccept: function (cookie) {
                // callback triggered on the first accept/reject action, and after each page load
            },

            onChange: function (cookie, changed_categories) {
                // callback triggered when user changes preferences after consent has already been given
            },

            languages: {
                'en': {
                    consent_modal: {
                        title: 'We use cookies!',
                        description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                        primary_btn: {
                            text: 'Accept',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        // secondary_btn: {
                        //     text: 'Reject all',
                        //     role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                        // }
                    },
                    settings_modal: {
                        title: 'Cookie preferences',
                        save_settings_btn: 'Save settings',
                        accept_all_btn: 'Accept all',
                        reject_all_btn: 'Reject non required',
                        close_btn_label: 'Close',
                        cookie_table_headers: [
                            { col1: 'Name' },
                            { col2: 'Domain' },
                            { col3: 'Expiration' },
                            { col4: 'Description' }
                        ],
                        blocks: [
                            {
                                title: 'Cookie usage 📢',
                                description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for some categories to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                            }, {
                                title: 'Strictly necessary cookies',
                                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                }
                            }, {
                                title: 'Performance and Analytics cookies',
                                description: 'These cookies allow the website to remember the choices you have made in the past and understand how you interact with it',
                                toggle: {
                                    value: 'analytics',     // your cookie category
                                    enabled: true,
                                    readonly: true
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '^_ga',       // match all cookies starting with "_ga"
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Used to distinguish users.',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_ga_9MMNN25WHJ',
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Used to persist session state.',
                                    }
                                ]
                            }, {
                                title: 'Advertisement and Targeting cookies',
                                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                                toggle: {
                                    value: 'targeting',
                                    enabled: false,
                                    readonly: false
                                }
                            }, {
                                title: 'More information',
                                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                            }
                        ]
                    }
                }
            }
        });


    });



}(jQuery));

function openVideo() {
    // Create video element
    const video = document.createElement('video');

    // Use local file
    // video.src = 'video.mp4';

    // Use remote file
    video.src =
        'assets/video/test-video.mp4';


    video.controls = true;
    video.muted = false;
    video.height = 240; // in px
    video.width = 320; // in px

    const box = document.getElementById('box');

    // Include in HTML as child of #box
    box.appendChild(video);
    // video.requestFullscreen();
}
